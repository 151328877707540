



































































































































import { hasMissingRequiredKeys, showErrorAlert, showSuccessAlert } from "@/helpers";
import { primaryBody, secondaryBody, tertiaryBoldBody } from "@/helpers/styleClassHelpers";
import { IInsurerPlan } from "@/interfaces/insurer_plan";
import EntityForm from "@/views/entity/EntityForm.vue";
import { BOverlay } from "bootstrap-vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import CustomButton from "./Button.vue";
import EntityHeader from "./EntityHeader.vue";
import InitialsBadge from "./InitialsBadge.vue";

@Component({
	components: {
		InitialsBadge,
		CustomButton,
		EntityForm,
		EntityHeader,
		BOverlay,
	},
})
export default class TabsContent extends Vue {
	@Prop({ default: () => [] }) tabs!: any[];
	@Prop({ default: () => [] }) tabHeaders!: any[];

	primaryBody = primaryBody;
	secondaryBody = secondaryBody;
	tertiaryBoldBody = tertiaryBoldBody;

	entityForm = EntityForm;
	currentEntity: any = {
		insurerPlans: [],
	};
	editItem = {};
	model = {} as any;
	additionalModel = {} as any;
	isLoading = false;
	deleteKeysOnSend = ["localId", "isLocal", "updatedDate", "createdDate"];

	get formId() {
		return this.$route.params.id;
	}

	currentTab = {};

	corporateClientFormRef: any = {};
	listPlansRef: any = {};
	planFormRef: any = {};

	async mounted() {
		this.currentTab = this.tabs[0];
		this.model = this.$attrs.model;
		this.loadReadOnly();

		const leftComponents: any[] = <any>this.$refs.leftComponents;
		const rightForms: any[] = <any>this.$refs.rightForms;
		this.corporateClientFormRef = leftComponents[0]; //EntityForm.vue (corporate_client)
		this.listPlansRef = leftComponents[1]; //ListPlans.vue
		this.planFormRef = rightForms[0]; //EntityForm.vue (insurer_plan)
	}

	loadReadOnly() {
		if (this.formId !== "novo") {
			this.tabs.forEach(tab =>
				tab.tabKind === "editionTab"
					? tab.formColumns.forEach((column: any) => (column.isReadOnly = true))
					: tab.formColumns.forEach((column: any) =>
							column.key === "id" ? (column.isReadOnly = true) : (column.isReadOnly = false),
					  ),
			);
		}
	}

	get currentRoute() {
		return this.$route.path;
	}

	onEntityLoad(entity: any) {
		this.currentEntity = entity;
	}

	onPlanRemoval(plan: IInsurerPlan) {
		const index = this.findPlanIndex(plan);
		this.currentEntity.insurerPlans.splice(index, 1);
		this.listPlansRef.selectedPlan = {};
		this.planFormRef.entity = { ...this.planFormRef.defaultEntity };
		this.planFormRef.handleEditionLock(false);
		this.editItem = "novo";
	}

	async inputChange(item: IInsurerPlan | "novo") {
		const itemClone: any = typeof item === "string" ? {} : { ...item }; //to avoid direct changes (ex: changing a text field would change the value to any other object referencing this "item")
		this.planFormRef.isEntityLoading = true;
		await this.planFormRef?.loadEntityBaseFunctionality();
		await this.planFormRef?.changeFields();
		if (!itemClone?.isLocal) {
			this.editItem = typeof item === "string" ? item : itemClone;
			if (item === "novo") {
				this.planFormRef.entity.servicePlans = [];
				this.planFormRef.handleEditionLock(false);
			}
			//selected a local item (not saved, created by the user)
		} else if (itemClone?.isLocal) {
			this.editItem = { ...itemClone, id: "local" };
		}
		this.planFormRef.entity = { ...this.planFormRef.entity, ...itemClone };
		this.planFormRef.isEntityLoading = false;
	}

	toggleCurrentTab(tab: any) {
		this.currentTab = tab;
	}

	async createCorporateClient() {
		this.isLoading = true;
		try {
			await this.cleanForm(this.currentEntity);
			const entity = await this.tabs[0].model.create(this.currentEntity);
			showSuccessAlert("Cliente corporativo e planos criados com sucesso");
			await this.$router.replace(this.$router.currentRoute.path.replace("novo", entity.id));
			location.reload();
		} catch (error: any) {
			console.error(error);
			showErrorAlert(error?.friendlyMessage || "Ocorreu um erro. Tente novamente.");
		} finally {
			this.isLoading = false;
		}
	}

	async updateCorporateClient() {
		this.isLoading = true;
		try {
			await this.cleanForm(this.currentEntity);
			delete this.currentEntity.id;
			const entity = await this.tabs[0].model.update(this.formId, this.currentEntity);
			showSuccessAlert("Cliente corporativo e planos atualizados com sucesso");
			location.reload();
		} catch (error: any) {
			console.error(error);
			showErrorAlert(error?.friendlyMessage || "Ocorreu um erro. Tente novamente.");
		} finally {
			this.isLoading = false;
		}
	}

	async cleanForm(entity: any) {
		Object.keys(entity).forEach((key: string) => {
			if (this.deleteKeysOnSend.includes(key) || !entity[key]) {
				delete entity[key];
			} else if (typeof entity[key] === "object") {
				this.cleanForm(entity[key]);
			}
		});
	}

	headerButtons = [
		{ icon: "edit-icon.svg", variant: "tertiary", buttonAction: this.unlockEdition },
		{ icon: "trash-icon.svg", variant: "tertiary", buttonAction: this.deleteEditItem },
	];

	unlockEdition() {
		this.corporateClientFormRef.handleEditionLock(false);
	}

	goBack() {
		this.$router.back();
		this.unlockEdition();
	}

	deleteEditItem() {
		this.corporateClientFormRef.remove();
	}

	refreshList(entity: IInsurerPlan, action: "add" | "update") {
		const newPlan = { ...entity, isLocal: true };
		if (action === "add") {
			newPlan.localId = Math.random();
			this.currentEntity.insurerPlans.push(newPlan);
			this.listPlansRef.selectedPlan = newPlan;
		} else if (action === "update") {
			const index = this.findPlanIndex(newPlan);
			this.currentEntity.insurerPlans[index] = newPlan;
			this.listPlansRef.selectedPlan = newPlan;
		}
		this.inputChange(newPlan);
	}

	findPlanIndex(plan: IInsurerPlan) {
		return this.currentEntity.insurerPlans.findIndex((insurerPlan: any) => {
			return (
				(insurerPlan.localId === plan.localId && insurerPlan.localId !== undefined) ||
				(insurerPlan?.id === plan?.id && insurerPlan?.id !== undefined)
			);
		});
	}

	updateServiceList() {
		this.currentEntity.insurerPlans.forEach((plan: IInsurerPlan) => {
			delete plan.isLocal;
		});
		this.currentEntity.insurerPlans = [...this.currentEntity.insurerPlans];
	}

	get buttonDisabled() {
		return hasMissingRequiredKeys(this.tabs[0].formColumns, this.currentEntity);
	}
}
