var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{staticClass:"flex gap-x-10 h-full",attrs:{"show":_vm.isLoading}},[_c('div',{staticClass:"flex flex-col flex-1"},[_c('custom-button',{staticClass:"mb-2 self-start",attrs:{"variant":"tertiary","icon":"arrow-back.svg","iconStyle":"h-4 w-4 mr-1","onClick":_vm.goBack}},[_vm._v(" Voltar ")]),(_vm.formId !== 'novo')?_c('div',{staticClass:"flex flex-row justify-between mb-1"},[_c('EntityHeader',{attrs:{"model":_vm.currentEntity,"modelHeaders":_vm.tabHeaders}}),_c('div',_vm._l((_vm.headerButtons),function(button,indexB){return _c('custom-button',{key:indexB,class:button === _vm.headerButtons[_vm.headerButtons.length - 1] ? 'ml-1' : '',attrs:{"onClick":button.buttonAction,"variant":button.variant,"icon":button.icon,"iconStyle":"w-6 h-6 cursor-pointer","disabled":button.disabled}},[_vm._v(_vm._s(button.label))])}),1)],1):_vm._e(),(_vm.tabs.length)?_c('div',{staticClass:"h-full flex-1 flex flex-col overflow-hidden"},[_c('div',{staticClass:"flex gap-x-4 mb-1"},_vm._l((_vm.tabs),function(tab,tabIndex){return _c('div',{key:tabIndex,staticClass:"cursor-pointer",class:[_vm.currentTab === tab ? _vm.tertiaryBoldBody : '']},[_c('span',{on:{"click":function($event){return _vm.toggleCurrentTab(tab)}}},[_vm._v(_vm._s(tab.name))])])}),0),_c('div',{staticClass:"overflow-auto scroll"},_vm._l((_vm.tabs),function(tab,tabIndex){return _c('div',{key:tabIndex},[_c(tab.component,_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === tab),expression:"currentTab === tab"}],ref:"leftComponents",refInFor:true,tag:"component",attrs:{"onSuccessfullyLoadingEntity":_vm.onEntityLoad},on:{"inputChange":_vm.inputChange,"updateServiceList":_vm.updateServiceList}},'component',Object.assign({}, _vm.$attrs,
							{model: tab.model,
							isCustomEntity: true,
							customEntity: _vm.currentEntity,
							plans: _vm.currentEntity.insurerPlans,
							formColumns: tab.formColumns,
							loadMounted: tab.loadMounted,
							kind: 'tableAndForm',
							withPostalCode: true,
							entityFormHeaders: tab.entityFormHeaders,
							isTableAndForm: tab.isTableAndForm,
							formContainerVariant: tab.formContainerVariant,
							withEntityHeader: false,
							cleanFormKeys: tab.cleanFormKeys}),false))],1)}),0)]):_c('div',{staticClass:"overflow-auto scroll"},[_c('entity-form',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var entity = ref.entity;
return [_vm._t("entityFormSlot",null,{"entity":entity})]}}],null,true)},'entity-form',Object.assign({}, _vm.$attrs),false))],1),_c('CustomButton',{staticClass:"self-center mt-3",attrs:{"width":"60%","disabled":_vm.buttonDisabled,"onClick":_vm.formId === 'novo' ? _vm.createCorporateClient : _vm.updateCorporateClient}},[_vm._v(" Salvar Cliente Corporativo e Planos ")])],1),(_vm.tabs.length)?_c('div',{staticClass:"mt-14 flex-1"},_vm._l((_vm.tabs),function(tab,tabIndex){return _c('div',{key:tabIndex},[(tab.content)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === tab && (_vm.editItem.id || _vm.editItem === 'novo')),expression:"currentTab === tab && (editItem.id || editItem === 'novo')"}]},[_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"flex flex-col w-full"},[_c('h2',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.editItem !== "novo" ? tab.content.title : tab.content.alternativeTitle))])])]),_c('div',{},[_c('entity-form',_vm._b({ref:"rightForms",refInFor:true,staticClass:"lg:h-60vh 2xl:h-full",attrs:{"formStyleVariant":"secondary","emitAfterCreate":true},on:{"addInsurerPlan":_vm.refreshList}},'entity-form',Object.assign({}, _vm.$attrs,
							{withTitle: false,
							isCustomEntity: true,
							customEntity: _vm.currentEntity,
							model: tab.model,
							additionalModel: tab.additionalModel,
							title: tab.name,
							formColumns: tab.formColumns,
							loadMounted: tab.loadMounted,
							idRead: _vm.editItem.id || _vm.editItem,
							withPostalCode: true,
							entityFormHeaders: tab.entityFormHeaders,
							kind: tab.kind,
							isTableAndForm: tab.isTableAndForm,
							formContainerVariant: tab.formContainerVariant,
							withEntityHeader: tab.withEntityHeader,
							defaultEntity: tab.defaultEntity,
							buttonText: tab.buttonText,
							onSuccessText: tab.onSuccessText,
							shouldGoToPreviousPageAfterRemove: tab.shouldGoToPreviousPageAfterRemove,
							customRemove: _vm.onPlanRemoval}),false))],1)]):_vm._e()])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }