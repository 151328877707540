












































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";

import EntityFilter, { FilterDefinition } from "./EntityFilter.vue";
import EntityTable from "./EntityTable.vue";
import EntityForm from "./EntityForm.vue";
import TabsContent from "@/layouts/components/TabsContent.vue";
import CustomButton from "@/layouts/components/Button.vue";
import CreateCategory from "@/layouts/components/CreateCategory.vue";

@Component({
	components: { EntityFilter, EntityTable, EntityForm, TabsContent, CustomButton, CreateCategory },
})
export default class EntityCrud extends Vue {
	@Prop() model: any;
	@Prop() shouldGoToPreviousPageAfterRemove: boolean;
	@Prop() onRemoveEntity: Function;
	@Prop() creationRoute: string;
	@Prop() title: string;
	@Prop() kind: string;
	@Prop({ default: "" }) formStyleVariant: string;
	@Prop({ default: "" }) formContainerVariant: string;
	@Prop() titleKind: string;
	@Prop() tableColumns: any[];
	@Prop() filter: any;
	@Prop({ default: () => [] }) highlightRowsWithFilters: { filter: any; backgroundColor: string }[];
	@Prop() filterDefinition: FilterDefinition;
	@Prop() formColumns: any[];
	@Prop() defaultEntity: any;
	@Prop({ default: undefined }) initialSort: { key: string; direction: "ASC" | "DESC" };
	@Prop({ default: undefined }) eventType: string;
	@Prop({ default: false }) isReadOnly: boolean;
	@Prop({ default: false }) showForm!: boolean;
	@Prop({ default: true }) enableCreation: boolean;
	@Prop({ default: true }) enableDelete: boolean;
	@Prop({ default: false }) withEntityHeader: boolean;
	@Prop({ default: false }) isCategory: boolean;
	@Prop({ default: true }) shouldShowExportButton!: boolean;
	@Prop({ default: null }) onRowClick!: (i: string) => void;
	@Prop({ default: () => [] }) actions: {
		kind: "TABLE" | "FORM" | "ALL";
		name: string;
		icon: string;
		color: string;
		action: any;
	}[];
	@Prop({ default: () => [] }) galleries: {
		name: string;
		entityKey: string;
		mediaEntityModel: any;
		mediaEntityFilter: any;
		mediaEntityOrderKey: string;
		mediaEntityMediaUrlKey: string;
	}[];
	@Prop({ default: "primary" }) tableVariant!: string;
	@Prop({ default: "" }) searchLabel!: string;
	@Prop({ default: () => [] }) tabs!: any[];
	@Prop({ default: () => [] }) entityFormHeaders!: any[];
	@Prop({ default: true }) shouldShowHeaderName!: boolean;
	@Prop({ default: true }) shouldShowButtons!: boolean;
	@Prop({ default: false }) shouldShowCustomButtons!: boolean;
	@Prop({ default: false }) shouldShowDateSearch: boolean;
	@Prop({ default: false }) shouldDisableRowClick: boolean;
	@Prop({ default: () => [] }) tabHeaders!: any[];
	@Prop({ default: true }) loadMounted: boolean;
	@Prop({ default: false }) withPostalCode: boolean;
	@Prop({ default: false }) isTableAndForm: boolean;
	@Prop({ default: () => null }) onInputChange!: (inputKey?: string) => void;
	@Prop({ default: () => null }) onLoadingEntity!: Function;
	@Prop({ default: () => ({}) }) cleanFormKeys: any;
	@Prop({ default: true }) withBackButton: boolean;
	@Prop({ default: true }) withTitle!: boolean;
	@Prop() exportColumns: (string | { key: string; name: string; valueTransform: Function })[];
	@Prop({ default: () => [] }) customButtons!: any;

	filterEntity: any = {};

	get id() {
		return this.$route.params.id;
	}

	get tableActions() {
		return this.actions.filter(action => action.kind === "TABLE" || action.kind === "ALL");
	}

	get formActions() {
		return this.actions.filter(action => action.kind === "FORM" || action.kind === "ALL");
	}

	get combinedFilter() {
		return { ...this.filter, ...this.filterEntity };
	}

	get props() {
		return this.$props;
	}

	refresh() {
		(this.$refs.crudTable as any)?.refresh();
	}

	openChannel(cardData: any) {
		this.$emit("openChannel", cardData);
	}

	relateClient(cardData: any) {
		this.$emit("relateClient", cardData);
	}

	findClient(cardData: any) {
		this.$emit("findClient", cardData);
	}

	actionsEvent(event: string, row: any) {
		this.$emit("actionsEvent", event, row);
	}
}
