import { Client } from "@/entities/client";
import { IUser } from "./user";
import { ICorporateClient } from "./corporate_client";

export class ChatChannel {
	id: string;
	chatMembers: ChatMember[];
	lastChatMessage: ChatMessage;
	channelFixationDate: Date;
	firstClientMessageDate?: Date;
	phoneNumber: string;
	status: ChatChannelStatus;
	corporateClient: ICorporateClient;
}

export enum ChatMessageStatus {
	QUEUED = "QUEUED",
	SENT = "SENT",
	DELIVERED = "DELIVERED",
	UNDELIVERED = "UNDELIVERED",
	READ = "READ",
	ERROR = "ERROR",
	FAILED = "FAILED",
}

export enum ChatMessageKind {
	DEFAULT = "DEFAULT",
	BOT = "BOT",
	HSM = "HSM",
	RATING_QUESTION = "RATING_QUESTION",
	RATING_ANSWER = "RATING_ANSWER",
	RECEPTIVE = "RECEPTIVE",
}

export class ChatMessage {
	id: string;
	text: string;
	phoneNumber?: string;
	user?: IUser;
	isRead?: boolean;
	chatChannel: string;
	status: ChatMessageStatus;
	createdDate: Date;
	kind: ChatMessageKind;
	mediaFiles: string[];
}

export enum ChatMemberKind {
	NOT_REGISTERED = "NOT_REGISTERED",
	CLIENT = "CLIENT",
	USER = "USER",
}

export class ChatMember {
	id: string;
	kind: ChatMemberKind;
	user?: IUser;
	client?: Client;
	chatChannel: string;
	isMainMember: boolean;
}

export enum ChatChannelStatus {
	OPENED = "OPENED",
	CLOSED = "CLOSED",
}

export enum ChatMessageAction {
	// enviado pelo servidor
	REGISTERED = "REGISTERED", // confirmação de registro do usuário (enviado junto com propriedade user)
	NEW_MESSAGE = "NEW_MESSAGE", // nova mensagem foi publicada
	EDIT = "EDIT", // mensagem foi editada
	STATUS_CHANGE = "STATUS_CHANGE",
	ACKNOWLEDGED = "ACKNOWLEDGED", // confirmação de recebimento de mensagem
	ERROR = "ERROR", // enviado junto com propriedade error
	REFRESH_CHANNELS = "REFRESH_CHANNELS", // notificação para atualizar canais

	// enviado pelo cliente ou pelo servidor
	DELETE_FOR_ME = "DELETE_FOR_ME", // mensagem foi ocultada para o usuário
	DELETE_FOR_EVERYONE = "DELETE_FOR_EVERYONE", // mensagem foi apagada do canal
}

export class ChatMessageMetadata {
	kind: string;
}
