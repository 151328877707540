






















import { Component, Vue } from "vue-property-decorator";
import { crudGet } from "@/api/_request";
import { tertiaryBody } from "@/helpers/styleClassHelpers";
import CustomSelect from "./Select.vue";
import CustomButton from "./Button.vue";
import { ICategory } from "@/interfaces/solution_category";

@Component({
	components: {
		"custom-select": CustomSelect,
		"custom-button": CustomButton,
	},
})
export default class CreateCategory extends Vue {
	tertiaryBody = tertiaryBody;

	categorySelected: ICategory | null = null;

	showForm = false;
	createCategory = true;

	categories: ICategory[] = [];

	get formattedCategories() {
		const optionsFormatted = this.categories.map((category: ICategory) => {
			const options = {
				name: category.name,
				value: category.id,
			};
			return options;
		});
		return optionsFormatted;
	}

	newCategory() {
		this.showForm = true;
		this.createCategory = false;
	}

	goBack() {
		this.$router.back();
	}

	async mounted() {
		this.categories = await crudGet("admin", "solutionCategory");
	}
}
