
















import { Component, Prop, Vue } from "vue-property-decorator";
import CustomSearch from "./CustomSearch.vue";
import CustomButton from "./Button.vue";

@Component({
	components: {
		"custom-search": CustomSearch,
		"custom-button": CustomButton,
	},
})
export default class AdminHeader extends Vue {
	@Prop({ default: () => [] }) buttons!: any[];
}
