




























































































































import { BButton, BFormInput, BIcon, BInputGroupPrepend, BOverlay, BPagination, BTable } from "bootstrap-vue";
import { format, intervalToDuration, parseISO, startOfDay } from "date-fns";
import debounce from "debounce";
import * as Slugify from "slugify";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import XLSX from "xlsx";

import { FieldDescription } from "@/api/_crud";
import { Client } from "@/entities/client";
import { requireConfirmation, showErrorAlert, showSuccessAlert } from "@/helpers";
import { readableDay, toIsoDate } from "@/helpers/calendar";
import { normalize } from "@/helpers/utils";
import { AppointmentStatus } from "@/interfaces/appointment";
import { ChatChannel, ChatChannelStatus, ChatMemberKind, ChatMessageKind } from "@/interfaces/chat";
import AdminHeader from "@/layouts/components/AdminHeader.vue";
import CustomButton from "@/layouts/components/Button.vue";
import Calendar from "@/layouts/components/Calendar.vue";
import CustomSearch from "@/layouts/components/CustomSearch.vue";
import MenuButton from "@/layouts/components/MenuButton.vue";
import Table from "@/layouts/components/Table.vue";
import EntityForm from "@/views/entity/EntityForm.vue";
import CustomCard from "../../layouts/components/CustomCard.vue";

const slugify: any = Slugify;

@Component({
	components: {
		BButton,
		BFormInput,
		BTable,
		BPagination,
		BOverlay,
		CustomButton,
		BInputGroupPrepend,
		BIcon,
		EntityForm,
		"custom-card": CustomCard,
		"admin-header": AdminHeader,
		"custom-search": CustomSearch,
		"custom-table": Table,
		"menu-button": MenuButton,
		Calendar,
	},
})
export default class EntityTable extends Vue {
	@Prop() title: string;
	@Prop() kind: string;
	@Prop() model: any;
	@Prop() onRemoveEntity: Function;
	@Prop() shouldGoToPreviousPageAfterRemove: boolean;
	@Prop() columns: (
		| string
		| {
				key: string;
				name: string;
				kind: string;
				actions: { icon: string; eventName: string; text: string; type: "GuideBalloon" | "VueIcon"; style: string }[];
				valueTransform: Function;
		  }
	)[];
	@Prop() exportColumns: (string | { key: string; name: string; valueTransform: Function })[];
	@Prop({ default: 15 }) pageLimit: number;
	@Prop({ default: () => {} }) filter: any;
	@Prop({ default: true }) enableCreation: boolean;
	@Prop({ default: true }) shouldShowButtons: boolean;
	@Prop({ default: false }) shouldShowCustomButtons!: boolean;
	@Prop({ default: undefined }) customButtons?: any;
	@Prop({ default: false }) shouldShowDateSearch: boolean;
	@Prop({ default: false }) shouldDisableRowClick: boolean;
	@Prop() creationRoute: string;
	@Prop() searchLabel: string;
	@Prop({ default: undefined }) initialSort: { key: string; direction: "ASC" | "DESC" };
	@Prop({ default: undefined }) eventType: string;
	@Prop({ default: false }) isReadOnly: boolean;
	@Prop({ default: false }) multiple: boolean;
	@Prop({ default: false }) preventNavigation: boolean;
	@Prop({ default: false }) showRowNumber: boolean;
	@Prop({ default: (event: Event) => null }) onClick!: (event: Event) => void;
	@Prop({ default: null }) onRowClick!: (i: string) => void;
	@Prop({ default: () => [] }) highlightRowsWithFilters: { filter: any; backgroundColor: string }[];

	@Prop({ default: () => [] }) actions: {
		name: string;
		icon: string;
		color: string;
		action: any;
	}[];
	@Prop({ default: "primary" }) tableVariant!: string;

	@Prop() formColumns: (
		| string
		| {
				key: string;
				name: string;
				isReadOnly?: boolean;
				updateReadOnly?: boolean;
				createOnly?: boolean;
				updateOnly?: boolean;
				validationToShow: (entity: any) => boolean;
		  }
	)[];
	@Prop({ default: () => [] }) joinKeys: string[];
	@Prop({ default: () => {} }) defaultEntity: any;
	@Prop({ default: true }) enableDelete: boolean;
	@Prop({ default: "" }) userName: string;
	@Prop({ default: false }) showForm!: boolean;
	@Prop({ default: true }) shouldShowExportButton!: boolean;
	@Prop({ default: "id" }) idParamKey: string;
	@Prop({ default: () => [] }) formActions: {
		name: string;
		icon: string;
		color: string;
		action: any;
	}[];

	isCalendarOpen = false;
	selectedDate = "";
	today = startOfDay(new Date()).toISOString();
	toIsoDate = toIsoDate;

	localFilter: any = {};
	selected = [] as any;
	synchronizeSelected = false;
	specialKeys = ["rowNumber", "selected", "actions"];

	firstKeyType: string = "name";
	secondKeyType: string = "name";

	buttons = [
		{ icon: "add-icon.svg", variant: "tertiary", buttonAction: this.create, iconStyle: undefined },
		{
			icon: "download-icon.svg",
			variant: "tertiary",
			buttonAction: this.exportSearch,
			iconStyle: undefined,
		},
		{
			icon: "trash-icon.svg",
			variant: "tertiary",
			buttonAction: this.remove,
			iconStyle: undefined,
		},
	];

	entityDescription: any = [];

	// current search state
	isTableLoading = false;
	currentPage: number = 1;
	currentSearchTerm: string = "";
	refinedSearchTerm: string = "";
	currentSortKey: string = "";
	currentSortDesc: boolean = false;
	currentTotal: number = 0;
	orderable: boolean = true;

	results: any = [];
	clientList: any = [];

	debouncedSearch: any;

	constructor() {
		super();
		this.debouncedSearch = debounce(this.handleSearch, 300);
	}

	async clearDateSearch() {
		this.selectedDate = "";
		this.searchData.currentSearchTerm.value = "";
		await this.getAppointments(true, "");
	}

	get readableDate() {
		return this.selectedDate ? readableDay(this.selectedDate) : readableDay(this.today);
	}

	async mounted() {
		const exportButtonIndex = this.buttons.findIndex(button => button.icon === "download-icon.svg");

		if (!this.shouldShowExportButton && exportButtonIndex !== -1) {
			this.buttons.splice(exportButtonIndex, 1);
		}

		this.buttons = this.shouldShowCustomButtons ? this.customButtons : this.shouldShowButtons ? this.buttons : [];

		if (this.kind !== "cardsTable") {
			this.entityDescription = await this.model.getDescription();
		}
		if (this.initialSort) {
			this.currentSortDesc = this.initialSort.direction === "DESC";
			this.currentSortKey = this.initialSort.key;
		} else {
			this.currentSortDesc = false;
			this.currentSortKey =
				(
					this.tableFields.find(field => field.defaultSort) ||
					this.tableFields.find(field => !this.specialKeys.includes(field.key))
				)?.key ?? "";
		}

		this.$root.$on("refreshTable", this.refresh);
		this.refresh();
	}

	beforeDestroy() {
		this.$root.$off("refreshTable");
	}

	updateSelected(items: any[]) {
		this.synchronizeSelected = false;
		this.selected = [...this.selected, ...items];
	}

	searchFields = [
		{
			label: "Digite um Nome, CPF, Data de nascimento, Carteirinha ou Telefone.",
			key: "currentSearchTerm",
			placeholder: "Pesquisar...",
		},
		{
			label: "Refine pelo Nome, CPF, Data de nascimento, Carteirinha ou Telefone do cliente",
			key: "refinedSearch",
			placeholder: "Refinar...",
			icon: "none",
			searchType: "second",
		},
	];
	searchData = { currentSearchTerm: { value: "", type: "text" }, refinedSearch: { value: "", type: "text" } };

	setSelectFilter({ key, value }: any) {
		const lastLocalFilter = { ...this.localFilter };
		if (value === "all") {
			delete this.localFilter[key];
		} else {
			this.localFilter[key] = value;
		}
		if (JSON.stringify(lastLocalFilter) !== JSON.stringify(this.localFilter)) {
			//WARN to be captured by @Watch
			this.localFilter = { ...this.localFilter };
		}
	}

	sortTable(header: any) {
		this.orderable = header.orderable === false ? false : true;
		if (this.currentSortKey === header.key) {
			this.currentSortDesc = !this.currentSortDesc;
		} else {
			this.currentSortDesc = false;
			this.currentSortKey = header.key;
		}
	}

	getFieldsFromColumns(columns: any) {
		if (!this.entityDescription || !this.columns.length) {
			return [];
		}

		return columns.map((fieldDefinition: any) => {
			const field =
				this.entityDescription.find(
					(field: any) => field.key === (typeof fieldDefinition === "string" ? fieldDefinition : fieldDefinition.key),
				) || {};
			if (typeof fieldDefinition !== "string") {
				return {
					...field,
					...fieldDefinition,
				}; // if column definition was passed, copy its properties to field
			}
			return field;
		});
	}

	get tableFields(): FieldDescription[] {
		const fields: FieldDescription[] = this.getFieldsFromColumns(this.columns).map((field: any) => ({
			...field,
			key: field.key,
			label: field.name,
			sortable: true,
		}));
		if (this.multiple) {
			fields.unshift({ key: "selected", label: "Selecionar", sortable: false });
		}
		if (this.showRowNumber) {
			fields.unshift({ key: "rowNumber", label: "No.", sortable: false });
		}
		if (this.actions?.length) {
			fields.push({ key: "actions", label: "", sortable: false });
		}
		return fields;
	}

	get exportFields(): any[] {
		return this.getFieldsFromColumns(this.exportColumns);
	}

	get tableItems() {
		return this.results.map((rowData: any) => {
			return Object.fromEntries(
				[...this.tableFields, { key: "id" }].map(tableField => {
					return [tableField.key, this.getFieldContent(tableField, rowData)];
				}),
			);
		});
	}

	getFieldContent(field: any, rowData: any, separateKeys = true) {
		let value = {} as any;

		if (field.kind !== "actions") {
			if (field.getInformationOtherKey) {
				this.getInformationOtherKey(field.key, field.otherKey, field.relationKeyId, field.relationKeyName, rowData);
			}
			const separateKey = field.key ? field.key.split(".") : field.split(".");
			if (separateKeys && separateKey.length > 1 && field.kind !== "select") {
				value = separateKey.reduce((finalValue: any, key: any, indexKey: number) => {
					if (finalValue) {
						finalValue = finalValue[key];
						return finalValue;
					}
				}, rowData);
			} else {
				if (
					field.kind === "relation" &&
					field.relationType === "many-to-many" &&
					field.relationToTake &&
					field.relationPropertyToTake
				) {
					if (rowData[field.key].length) {
						value = rowData[field.key].reduce((value: string | undefined, data: any) => {
							if (!value) {
								value = data[field.relationToTake][field.relationPropertyToTake];
								return value;
							}

							return value.concat(`, ${data[field.relationToTake][field.relationPropertyToTake]}`);
						}, "");
					}
				} else {
					value = rowData[field.key];
				}
			}
			if (field.valueTransform || field.onTransform) {
				return field.valueTransform?.(value) || field.onTransform?.(value); // transform value with given function
			}
			if (field.kind && (!field.relationType || field.relationType !== "many-to-many")) {
				switch (field.kind) {
					case "select":
						return value ? field.options.find((option: any) => option.value === value)?.name : "-";
					case "datetime":
						return value ? format(parseISO(value), "dd/MM/yyyy HH:mm") : "-";
					case "date":
						return value ? format(parseISO(value), "dd/MM/yyyy") : "-";
					case "relation":
						return rowData[`${field.key}.name`];
					case "multipleText":
						return value ? value.join() : "-";
				}
			}

			if (field.nameComplement) {
				const extractedObject: any = {};
				const splittedKey = field.nameComplement.split(".");
				Object.assign(extractedObject, rowData[splittedKey[0]]);
				value = `${value}${extractedObject[splittedKey[1]] ? " " + extractedObject[splittedKey[1]] : ""}`;
			}
		} else {
			value = field;
		}

		return value;
	}

	getInformationOtherKey(key: string, otherKey: string, relationKeyId: string, relationKeyName: string, entity: any) {
		entity[otherKey]?.forEach((value: any) => {
			if (!entity[key]?.find((currentValue: any) => currentValue.id === value[relationKeyId])) {
				if (!entity[key]) {
					entity[key] = [];
				}
				entity[key].push({ id: value[relationKeyId], name: value[relationKeyName] });
			}
		});
	}

	async getJoinableFields(): Promise<{ field: string }[]> {
		const description = this.entityDescription;
		const relationFields = description.filter((field: any) => field.kind === "relation");
		const relationFieldsReduced = relationFields.reduce((fields: any, currentField: any) => {
			if (!fields.filter((fieldFilter: any) => fieldFilter.field === currentField.key).length) {
				fields.push({ field: currentField.key });
			}
			this.getRelationModel(currentField, fields);
			return fields;
		}, [] as any);

		return relationFieldsReduced;
	}

	getRelationModel(currentField: any, fields: any, parent = "") {
		if (currentField.model?.relationModels?.length) {
			currentField.model?.relationModels.forEach((model: any) => {
				if (
					!fields.filter(
						(fieldFilter: any) =>
							fieldFilter.field ===
							`${parent ? parent + "." : ""}${currentField.key || currentField.fieldKey}.${model.fieldKey}`,
					).length
				) {
					fields.push({
						field: `${parent ? parent + "." : ""}${currentField.key || currentField.fieldKey}.${model.fieldKey}`,
					});
				}
				this.getRelationModel(model, fields, currentField.key || currentField.fieldKey);
			});
		}
	}

	async getQueryParameters(fields: any[]) {
		const joinFields = await this.getJoinableFields();
		const filter: any = [];

		this.tableFields
			// filtra as colunas da tabela que contenham a palavra do currentSearchTerm
			.filter(field => field.kind !== "relation" && !this.specialKeys.includes(field.key))
			.forEach(field => {
				if (field.kind === "select" && field.options) {
					// como os campos de select são enum no backend, dentro do banco de dados eles são armazenados em inglês, por isso é necessária a conversão de português para inglês abaixo
					field.options.forEach((option: any) => {
						const currentSearchTerm = normalize(this.searchData.currentSearchTerm.value);
						const optionName = normalize(option.name);
						if (optionName.includes(currentSearchTerm)) {
							filter.push({
								[field.key]: {
									$cont: option.value,
								},
							});
						}
					});
				} else {
					filter.push({
						[field.key]: {
							$cont: this.searchData.currentSearchTerm.value,
						},
					});
				}
			});

		return {
			sort: [
				{
					field: this.currentSortKey,
					order: this.currentSortDesc ? "DESC" : "ASC",
				},
			],
			search: {
				...this.filter,
				...this.localFilter,
				$or: filter,
			},
			join: joinFields,
		};
	}

	async remove() {
		if (!this.selected.length && this.entityId === "") {
			showErrorAlert("Nenhum elemento selecionado, seleciona algum item para continuar");
			return;
		} else {
			await requireConfirmation("Confirma a exclusão?", "Não será possível reverter esta operação.", "Excluir");

			this.isTableLoading = true;
			try {
				if (this.kind === "tableAndForm") {
					await this.model.delete(this.entityId);
				}
				this.selected.forEach(async (item: any) => {
					await this.model.delete(item.id);
					this.refresh();
				});
				this.refresh();
				this.toggleForm = false;
			} catch (error: any) {
				console.error(error);
				showErrorAlert(error?.friendlyMessage || error.message || "Ocorreu um erro. Tente novamente.");
			}
			this.synchronizeSelected = true;
			showSuccessAlert("Os registros foram excluídos com sucesso.");
			this.isTableLoading = false;
		}
	}

	formatDateForAmerican(value: string) {
		const date = value.split("/");
		date.reverse();
		return date.reduce((finalDate: string, partialDate: string, currentIndex: number) => {
			finalDate = `${finalDate}${currentIndex !== 0 ? "-" : ""}${partialDate}`;
			return finalDate;
		}, "");
	}

	async handleSearch() {
		if (!this.currentSortKey) {
			return;
		}

		if (!this.orderable) {
			return;
		}
		this.isTableLoading = true;

		let currentSearchParam =
			this.searchData.currentSearchTerm.type === "birth"
				? this.formatDateForAmerican(this.searchData.currentSearchTerm.value)
				: this.searchData.currentSearchTerm.value;
		const currentAdditionalSearchParam =
			this.searchData.refinedSearch.type === "birth"
				? this.formatDateForAmerican(this.searchData.refinedSearch.value)
				: this.searchData.refinedSearch.value;

		const queryParameters = await this.getQueryParameters(this.tableFields);
		try {
			const response =
				this.kind === "cardsTable"
					? await this.model.getClientList({
							searchParam: currentSearchParam.trim(),
							searchParamType: currentSearchParam ? this.firstKeyType : undefined,
							additionalSearchParam: currentAdditionalSearchParam ? currentAdditionalSearchParam.trim() : undefined,
							additionalSearchParamType: currentAdditionalSearchParam ? this.secondKeyType : undefined,
							page: this.currentPage,
							limit: this.pageLimit,
							sort: {
								property:
									this.currentSortKey.split(".").length === 1 ? `client.${this.currentSortKey}` : this.currentSortKey,
								type: this.currentSortDesc ? "DESC" : "ASC",
							},
					  })
					: this.kind === "clientsTable"
					? await this.model.getClientAdminList({
							searchParam: this.searchData.currentSearchTerm.value,
							page: this.currentPage,
							limit: this.pageLimit,
							refinedSearchParamLiteral: this.localFilter,
							sort: {
								property:
									this.currentSortKey.split(".").length === 1 ? `client.${this.currentSortKey}` : this.currentSortKey,
								type: this.currentSortDesc ? "DESC" : "ASC",
							},
					  })
					: this.kind === "solutionAppointmentTable"
					? await this.getAppointments(false, queryParameters, this.searchData.currentSearchTerm.value)
					: this.kind === "chatChannelTable"
					? await this.getChannels(false, queryParameters, this.searchData.currentSearchTerm.value)
					: this.kind === "campaignTable"
					? await this.getCampaigns(false, queryParameters, this.searchData.currentSearchTerm.value)
					: this.kind === "messageTemplateTable"
					? await this.getMessageTemplates(false, queryParameters, this.searchData.currentSearchTerm.value)
					: await this.model.search({
							...queryParameters,
							page: this.currentPage,
							limit: this.pageLimit,
							searchParam: this.searchData.currentSearchTerm.value,
					  });

			await this.setTableProperties(response);
		} catch (error) {
			console.error(error);
		}
		this.isTableLoading = false;
	}

	async getMessageTemplates(shouldCallSetTableProperties = false, queryParameters: any, searchParam?: string) {
		const corporateClientIds = [queryParameters.search["corporateClient.name"]].filter((id: any) => id);
		const kind = queryParameters.search["kind"];

		const response = await this.model.getMessageTemplateList({
			page: this.currentPage,
			limit: this.pageLimit,
			searchParam,
			corporateClientIds,
			kind,
		});

		if (shouldCallSetTableProperties) {
			await this.setTableProperties(response);
		}

		return response;
	}

	async getCampaigns(shouldCallSetTableProperties = false, queryParameters: any, searchParam?: string) {
		const corporateClientId = queryParameters.search["corporateClient.name"] ?? undefined;
		const response = await this.model.getCampaigns({
			page: this.currentPage,
			limit: this.pageLimit,
			searchParam,
			corporateClientId,
		});

		if (shouldCallSetTableProperties) {
			await this.setTableProperties(response);
		}

		return response;
	}

	async getAppointments(shouldCallSetTableProperties = false, queryParameters: any, searchParam?: string) {
		const corporateClientId = queryParameters.search["client.corporateClient.name"] ?? undefined;

		const response = await this.model.getAppointments({
			page: this.currentPage,
			limit: this.pageLimit,
			searchParam: searchParam,
			returnDate: this.selectedDate ? toIsoDate(new Date(this.selectedDate)) : "",
			shouldOrderByAppointmentReturnDate: true,
			shouldGetWithoutDateFilter: true,
			isWithReturn: true,
			appointmentStatus: [AppointmentStatus.CONFIRMED],
			corporateClientId,
		});

		if (shouldCallSetTableProperties) {
			await this.setTableProperties(response);
		}

		return response;
	}

	async getChannels(shouldCallSetTableProperties = false, queryParameters: any, searchParam?: string) {
		const status = queryParameters.search["status"] ?? undefined;
		const corporateClientId = queryParameters.search["corporateClient.name"] ?? undefined;
		let response = await this.model.listChannels({
			page: this.currentPage,
			limit: this.pageLimit,
			searchParam,
			corporateClientId,
			status,
		});

		if (shouldCallSetTableProperties) {
			await this.setTableProperties(response);
		}

		let channels: ChatChannel[] = response.data;
		channels.forEach(channel => {
			const foundUserChatMember = channel.chatMembers.find(chatMember => chatMember.kind === ChatMemberKind.USER);
			const foundMainMember = channel.chatMembers.find(
				chatMember => chatMember.kind === ChatMemberKind.CLIENT && chatMember.isMainMember,
			);

			Object.assign(
				channel,
				foundUserChatMember ? { user: foundUserChatMember.user } : { user: { id: null, name: "-", lastName: "" } },
			);
			Object.assign(
				channel,
				foundMainMember
					? { mainMember: foundMainMember.client }
					: { mainMember: { id: null, name: "-", lastName: "" } },
			);

			let lastChatMessageCreatedDateDifference = "-";

			if (
				channel.lastChatMessage &&
				(channel.lastChatMessage.phoneNumber || channel.lastChatMessage.kind === ChatMessageKind.BOT) &&
				channel.status === ChatChannelStatus.OPENED
			) {
				const today = new Date();
				const duration = intervalToDuration({
					start: today,
					end: new Date(channel.lastChatMessage.createdDate),
				});
				lastChatMessageCreatedDateDifference = this.formatDuration(duration);
			}

			Object.assign(channel, { lastChatMessageCreatedDateDifference });
		});

		return response;
	}

	formatDuration(duration: Duration) {
		const { days, hours, minutes, seconds } = duration;
		const sumOfDaysAndHours = days && days > 0 ? days * 24 + (hours ?? 0) : hours;
		return `${String(sumOfDaysAndHours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(
			seconds,
		).padStart(2, "0")}`;
	}

	async setTableProperties(response: any) {
		// se a página selecionada não existe, retorna para primeira página e busca novamente
		// todo: melhorar essa validação para o kind de cardsTable
		if (response.page > response.pageCount && response.pageCount !== 0 && this.kind !== "cardsTable") {
			this.currentPage = 1;
			await this.handleSearch;
			return;
		}
		this.currentTotal = response.total;
		// adiciona numeração sequencial nas linhas
		if (this.showRowNumber) {
			response.data.forEach((result: any, resultIndex: number) => {
				result.rowNumber = (this.currentPage - 1) * this.pageLimit + resultIndex + 1;
			});
		}

		this.results = response.data;
	}

	async exportSearch() {
		this.$store.dispatch("app/showLoading");
		try {
			let exportData: any = [];
			let queryParameters = await this.getQueryParameters(this.exportFields);

			if (this.model.endpoint === "admin/client" && this.selected.length) {
				const clientIds = JSON.stringify(this.selected.map((client: Partial<Client>) => client.id));
				queryParameters = <any>{ clientIds };
			}

			let mainData = undefined;
			if (this.model.endpoint === "admin/client") {
				mainData = await this.model.getClientAdminList({
					searchParam: this.searchData.currentSearchTerm.value,
					page: 1,
					limit: 0,
				});
			} else {
				Object.assign(queryParameters, { page: 1, limit: 0 });

				mainData = await this.model.search(queryParameters);
			}

			const data = mainData.data ?? mainData;

			if (!this.selected.length || !exportData.length) {
				exportData = data;
			} else if (this.selected.length) {
				const idsOfSelectedItems = this.selected.map((item: any) => item.id);
				exportData = data.filter((data: any) => idsOfSelectedItems.includes(data.id));
			}

			// replace header names and translate select options
			const xlsData = exportData?.length
				? exportData.map((row: any) =>
						this.exportFields.reduce(
							(processedRow: any, field: any) => ({
								...processedRow,
								[field.name]: this.getFieldContent(field, row, true),
							}),
							{},
						),
				  )
				: [];

			const exportWS = XLSX.utils.json_to_sheet(xlsData, { header: this.exportFields.map(field => field.name) });
			const wb = XLSX.utils.book_new();
			const title = this.title || "Relatório";
			XLSX.utils.book_append_sheet(wb, exportWS, title);
			await XLSX.writeFile(
				wb,
				`${slugify(title, { lower: true, strict: true })}_${format(new Date(), "yyyyMMdd_HHmmss")}.xlsx`,
			);
		} catch (error) {
			console.error(error);
		}
		this.$store.dispatch("app/hideLoading");
	}

	create() {
		this.$router.push(this.creationRoute ? this.creationRoute : `${this.$router.currentRoute.path}/novo`);
	}

	@Watch("localFilter")
	@Watch("currentPage")
	@Watch("currentSortDesc")
	@Watch("currentSortKey")
	@Watch("searchData.currentSearchTerm.value")
	@Watch("searchData.refinedSearch.value")
	async handlePaginationSortingAndSearch() {
		this.debouncedSearch();
	}

	toggleForm = false;

	closeForm() {
		if (this.entityId !== "") {
			this.toggleForm = !this.toggleForm;
		}
	}
	entityId = "";

	openRow(row: any) {
		if (!this.shouldDisableRowClick) {
			if (this.onRowClick != null) {
				this.onRowClick(row.id);
				return;
			}

			if (!this.preventNavigation && row.id && !this.$route.path.includes("necessidades")) {
				this.$router.push(`${this.$router.currentRoute.path}/${row.id}`);
			} else if (!this.preventNavigation && row.id && this.$route.path.includes("necessidades")) {
				this.toggleForm = true;
				this.entityId = row.id;
			}
		}
	}

	actionsEvent(event: string, row: any) {
		this.$emit("actionsEvent", event, row);
	}

	openCard(card: any) {
		if (this.eventType) {
			if (this.eventType === "chat:search") {
				this.$emit("openChannel", card);
			} else if (this.eventType === "chat:relate") {
				this.$emit("relateClient", card);
			} else if (this.eventType === "client:find") {
				this.$emit("findClient", card);
			}
		} else if (!this.preventNavigation && card.id && this.kind === "cardsTable") {
			this.$router.push(`${this.$router.currentRoute.path}/${card.id}`);
		}
	}

	runAction(event: any, action: any, entityData: any) {
		event.stopPropagation();
		action(entityData);
	}

	async refresh() {
		this.results = []; // clear results
		this.debouncedSearch();
		this.toggleForm = false;
	}

	setKeyType(type: string, searchType: string) {
		searchType === "first" ? (this.firstKeyType = type) : (this.secondKeyType = type);
	}
}
