







import { Component, Prop, Vue, Watch } from "vue-property-decorator";

export type ButtonVariant = "primary" | "secondary";

@Component({
	components: {},
})
export default class MenuButton extends Vue {
	@Prop({ default: "primary" }) variant!: ButtonVariant;
	@Prop({ default: false }) disabled!: boolean;
	@Prop({ default: (event: Event) => null }) onClick!: (event: Event, buttonAction?: string) => void;
	@Prop({ default: "" }) buttonAction!: string;
	@Prop({ default: "" }) label!: string;

	baseCss = "rounded-lg focus:outline-none";
	primaryCss =
		"font-bold text-journey-black bg-journey-white p-1 shadow-md  rounded-lg whitespace-nowrap flex flex-row items-center justify-between";

	secondaryCss = "font-bold text-journey-black bg-journey-white p-1 rounded-lg whitespace-nowrap'";

	get buttonStyle() {
		let baseStyles = [this.baseCss];

		switch (this.variant) {
			case "primary":
				baseStyles.push(this.primaryCss);
				break;
			case "secondary":
				baseStyles.push(this.secondaryCss);
				break;
		}

		return baseStyles;
	}

	handleClick(event: Event) {
		event.preventDefault();
		if (this.onClick && typeof this.onClick === "function") {
			this.onClick(event, this.buttonAction);
		}
	}
}
